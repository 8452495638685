import axios from "axios";

const addItemsToOrder = async (orderID, dataset) => {
  
  let items = [];

    // dataset.forEach(item => {
        // items.push({
        //     ordersId: orderID,
        //     productId: item.Id,
        //     quantity: item.Quantity,
        //     price: item.Price,
        //     totalPrice : item.totalPrice,
        // })
    // });

    // console.log(items);
    
  try{


    for(let i = 0; i < dataset.length; i++){
      const response = await axios.post('productsOrdered/new', {
        ordersId: orderID,
        productId: dataset[i].Id,
        quantity: dataset[i].Quantity,
        price: dataset[i].Price,
        totalPrice : dataset[i].totalPrice,
    });
    // console.log(response)
  }

    

  }catch(err){
    console.log(err)
  }
}

export default addItemsToOrder